import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { HelmetDatoCms } from "gatsby-source-datocms"

import { Section, Container, Content } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import SocialShare from "@molecules/blog/socialShare"
import { media } from "@styles/breakpoints"
import Date from "@utils/date"
import Layout from "@utils/layout"
import RelatedPosts from "../components/molecules/blog/relatedPosts"
import { fluidSize } from "@styles/fluidSize"

const PostTemplate = ({ data }) => {
  const post = data.datoCmsPost
  const relatedPosts = data.relatedPosts

  return (
    <Layout>
      <HelmetDatoCms seo={post.seoMetaTags} />
      <article id="blog-post">
        <Section sm lgTop>
          <Container containerLarge>
            <Heading html="h6" level="sh" strong aqua>
              {post.category.title}
            </Heading>
            <PostTitle html="h1" level="h1" className="post-title">
              {post.title}
            </PostTitle>
            <PostInfo>
              <GatsbyImage
                image={post.author.avatar.gatsbyImageData}
                alt={post.author.avatar.alt}
                title={post.author.avatar.title}
              />
              <InfoContent>
                <Heading html="h3" level="h5" gray className="post-author">
                  {post.author.name}
                </Heading>
                <Heading
                  html="h3"
                  level="sh"
                  strong
                  lightGray
                  className="post--date"
                >
                  <Date date={post.meta.firstPublishedAt} />
                </Heading>
              </InfoContent>
            </PostInfo>
          </Container>
        </Section>
        <Section md>
          <Container containerLarge>
            {post.featuredImage && (
              <GatsbyImage
                image={post.featuredImage.gatsbyImageData}
                alt={post.featuredImage.alt}
                title={post.featuredImage.title}
              />
            )}
          </Container>
        </Section>
        <ContentSection md>
          <SocialShare post={post} />
          <PostContentContainer>
            <PostContent
              dangerouslySetInnerHTML={{
                __html: post.content,
              }}
            ></PostContent>
          </PostContentContainer>
        </ContentSection>
      </article>
      <RelatedPosts relatedPosts={relatedPosts} />
    </Layout>
  )
}

export default PostTemplate

const PostTitle = styled(Heading)`
  ${fluidSize(4, 32, 56, "vw", "font-size") +
  fluidSize(2, 20, 32, "vw", "margin-bottom")}
`

const PostInfo = styled.div`
  align-items: center;
  display: flex;
`

const InfoContent = styled.div`
  margin-left: 1rem;

  h3 {
    margin-bottom: 0.5rem;

    &:nth-of-type(2) {
      margin-bottom: 0;
    }
  }
`

const ContentSection = styled(Section)`
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;

  ${media.md`
    align-items: flex-start;
    flex-direction: row;
  `}
`

const PostContentContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${media.md`
    transform: translateX(-1.75rem);
   `}
`

const PostContent = styled(Content)`
  max-width: var(--container--md);

  .btn {
    appearance: none;
    background-color: var(--colorPrimary);
    border: transparent;
    border-radius: 10rem;
    color: var(--white);
    display: inline-block;
    font-size: 1rem;
    font-family: var(--typeFamily);
    font-weight: var(--headingWeight);
    text-align: center;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: var(--transitionBase);
    width: auto;

    &:hover {
      background-color: var(--aqua--80);
      box-shadow: 0 0 0 0.5px var(--colorPrimary),
        0 8px 12px 0 rgba(34, 33, 81, 0);
    }
  }
`

export const fetchPost = graphql`
  query fetchPost($slug: String!, $id: String!, $cat: String!) {
    datoCmsPost(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      author {
        name
        avatar {
          alt
          title
          gatsbyImageData(
            width: 56
            layout: CONSTRAINED
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
          )
        }
      }
      category {
        title
        slug
      }
      content
      featuredImage {
        alt
        title
        gatsbyImageData(
          width: 1200
          layout: CONSTRAINED
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
        )
      }
      id
      slug
      title
      date
      meta {
        firstPublishedAt
      }
    }
    relatedPosts: allDatoCmsPost(
      filter: { id: { ne: $id }, category: { id: { eq: $cat } } }
      limit: 3
    ) {
      nodes {
        author {
          name
          avatar {
            alt
            title
            gatsbyImageData(
              width: 120
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
          }
        }
        category {
          title
          slug
        }
        content
        featuredImage {
          alt
          title
          gatsbyImageData(
            width: 560
            layout: CONSTRAINED
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
          )
        }
        id
        slug
        title
        date
        meta {
          firstPublishedAt
        }
      }
    }
  }
`
