import React from "react"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import CardPost from "@molecules/cards/post"

const RelatedPosts = ({ relatedPosts }) => {
  return (
    <Section light md mdTop data-datocms-noindex>
      <Container xs>
        <Heading html="h3" level="sh" strong gray noMargin>
          RelatedPosts
        </Heading>
      </Container>
      <Container>
        <Flex>
          {relatedPosts.nodes.map((post, index) => (
            <Column third key={index}>
              <CardPost post={post} />
            </Column>
          ))}
        </Flex>
      </Container>
    </Section>
  )
}

export default RelatedPosts
