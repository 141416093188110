import React, { useEffect } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"

import { media } from "@styles/breakpoints"

const SocialShare = ({ post }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(DrawSVGPlugin)
    }
    gsap.set(".circle", {
      drawSVG: "0% 0%",
      transformOrigin: "50% 50%",
      rotation: -90,
    })
  }, [])

  const hoverOn = e => {
    const circle = e.target.querySelector(".circle")
    gsap.fromTo(
      circle,
      { drawSVG: "0% 0%", duration: 0.5 },
      { drawSVG: "0% 100%", duration: 0.5 }
    )
  }

  const hoverOff = e => {
    const circle = e.target.querySelector(".circle")
    gsap.fromTo(
      circle,
      { drawSVG: "0% 100%", duration: 0.5 },
      { drawSVG: "100% 100%", duration: 0.5 }
    )
  }

  return (
    <Social>
      <Share>
        <ShareLink
          href={`https://twitter.com/intent/tweet/?text=${post.title}&url=https://moonclerk.com/${post.slug}%2F&via=moonclerk`}
          target="_blank"
          onMouseEnter={hoverOn}
          onMouseLeave={hoverOff}
        >
          <Circle viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" fill="none" />
          </Circle>
          <CircleFront viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" fill="none" className="circle" />
          </CircleFront>
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            fill="none"
            viewBox="0 0 16 14"
          >
            <path
              fillRule="evenodd"
              d="M5.032 13.8c6.038 0 9.34-4.925 9.34-9.196 0-.14 0-.28-.01-.418A6.61 6.61 0 0016 2.512c-.6.262-1.235.433-1.885.51a3.256 3.256 0 001.443-1.789 6.643 6.643 0 01-2.084.784 3.323 3.323 0 00-4.645-.14 3.205 3.205 0 00-.95 3.088A9.374 9.374 0 011.114 1.59 3.204 3.204 0 002.13 5.903a3.297 3.297 0 01-1.49-.404v.04c0 1.539 1.102 2.864 2.634 3.168-.484.13-.99.15-1.482.056a3.28 3.28 0 003.066 2.245A6.652 6.652 0 010 12.348a9.404 9.404 0 005.032 1.45"
              clipRule="evenodd"
            ></path>
          </Icon>
        </ShareLink>
      </Share>
      <Share>
        <ShareLink
          href={`https://www.facebook.com/sharer/sharer.php?u=https://moonclerk.com/${post.slug}`}
          target="_blank"
          onMouseEnter={hoverOn}
          onMouseLeave={hoverOff}
        >
          <Circle viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" fill="none" />
          </Circle>
          <CircleFront viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" fill="none" className="circle" />
          </CircleFront>
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="16"
            fill="none"
            viewBox="0 0 8 16"
          >
            <path d="M5.5 3H8V0H5.5C3.57 0 2 1.57 2 3.5V5H0v3h2v8h3V8h2.5L8 5H5V3.5c0-.271.229-.5.5-.5z"></path>
          </Icon>
        </ShareLink>
      </Share>
      <Share>
        <ShareLink
          href={`https://www.linkedin.com/shareArticle?mini=true&url=https://moonclerk.com/${post.slug}&title=${post.title}&source=MoonClerk`}
          target="_blank"
          onMouseEnter={hoverOn}
          onMouseLeave={hoverOff}
        >
          <Circle viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" fill="none" />
          </Circle>
          <CircleFront viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" fill="none" className="circle" />
          </CircleFront>
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M16 16h-3.2v-5.6c0-1.535-.678-2.392-1.893-2.392C9.585 8.008 8.8 8.9 8.8 10.4V16H5.6V5.6h3.2v1.17s1.004-1.762 3.266-1.762C14.33 5.008 16 6.388 16 9.246V16zM1.954 3.937A1.96 1.96 0 010 1.968 1.96 1.96 0 011.954 0a1.96 1.96 0 011.952 1.968 1.96 1.96 0 01-1.952 1.969zM0 16h4V5.6H0V16z"
              clipRule="evenodd"
            ></path>
          </Icon>
        </ShareLink>
      </Share>
    </Social>
  )
}

export default SocialShare

const Social = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: var(--spacing--sm);
  position: relative;
  z-index: var(--zFront);

  ${media.md`
    flex-direction: column;
    left: 0rem;
    padding-bottom: 0;
    position: sticky;
    top: 20vh;
  `}
`

const ShareLink = styled.a`
  align-items: center;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  position: relative;
  width: 2.5rem;
`

const Circle = styled.svg`
  height: 3rem;
  left: -0.25rem;
  position: absolute;
  top: -0.25rem;
  width: 3rem;
  stroke: var(--gray--50);
  stroke-width: 3px;
`

const CircleFront = styled(Circle)`
  stroke: var(--colorPrimary);
`

const Icon = styled.svg`
  path {
    fill: var(--gray--50);
    transition: var(--transitionBase);
  }
`

const Share = styled.li`
  padding: 0.5rem;

  &:hover {
    ${Icon} {
      path {
        fill: var(--colorPrimary);
      }
    }
  }
`
